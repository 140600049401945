<template>
  <div>
    <b-overlay
      :show="loadingOverlay"
      rounded="sm"
    >
      <b-card v-if="errorMessage">
        <b-row class="mt-10">
          <p v-html="errorMessage" />
        </b-row>
      </b-card>

      <template v-else>
        <b-card :title="$t('spot.meuAgente.titleSaldo')">
          <b-tabs
            content-class="pt-1"
            justified
          >
            <b-tab
              v-for="resumo in user_data.resumoSaldos"
              :key="resumo.paridade"
            >
              <template #title>
                <b-row>
                  <b-col
                    md="2"
                    sm="2"
                  >
                    <b-avatar
                      :src="`https://cryptoboot.com.br/repo/icons/${resumo.paridade.toLowerCase()}.png`"
                      size="50px"
                    />
                  </b-col>
                  <b-col
                    md="6"
                    sm="6"
                  >
                    <h5>{{ resumo.paridade }}</h5>
                    <h2 class="font-weight-bolder">
                      {{ resumo.total }}
                    </h2>
                  </b-col>
                </b-row>
              </template>

              <b-row v-if="resumo.perfis.length == 0">
                <b-col lg="12">
                  <b-alert
                    variant="warning"
                    show
                  >
                    <div class="alert-body">
                      {{ $t('spot.meuAgente.investimentosDesativados', { paridade: resumo.paridade }) }}
                    </div>
                  </b-alert>
                </b-col>
              </b-row>

              <b-row
                v-for="item in resumo.perfis"
                :key="item.perfil"
              >
                <b-col lg="12">
                  <b-card-body class="statistics-body">
                    <h5>{{ $t('spot.meuAgente.configuracoes') }} {{ item.perfil }}</h5>
                    <b-row class="mt-2">
                      <b-col
                        xl="3"
                        sm="6"
                        class="mb-md-0 mb-xl-0"
                      >
                        <b-button
                          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                          v-b-tooltip.hover.top="$t('spot.meuAgente.valorConfigurado')"
                          variant="flat-info"
                          class="btn-icon"
                          block
                        >
                          <b-media no-body>
                            <b-media-aside

                              class="mr-2"
                            >
                              <b-avatar
                                size="48"
                                variant="light-info"
                              >
                                <feather-icon
                                  size="24"
                                  icon="ShoppingCartIcon"
                                />
                              </b-avatar>
                            </b-media-aside>
                            <b-media-body class="my-auto">
                              <h4
                                class="font-weight-bolder mb-0"
                                v-html="item.operacao"
                              />
                              <b-card-text class="font-small-3 mb-0">
                                {{ $t('spot.meuAgente.valorPorOperacao') }}
                              </b-card-text>
                              <b-progress
                                :value="item.operacaoPerc"
                                max="100"
                                class="progress-bar-info"
                                variant="info"
                              />
                            </b-media-body>
                          </b-media>
                        </b-button>
                      </b-col>
                      <b-col
                        xl="3"
                        sm="6"
                        class="mb-md-0 mb-xl-0"
                      >
                        <b-button
                          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                          v-b-tooltip.hover.top="$t('spot.meuAgente.saldoDisponivel')"
                          variant="flat-success"
                          class="btn-icon"
                          block
                        >
                          <b-media no-body>
                            <b-media-aside

                              class="mr-2"
                            >
                              <b-avatar
                                size="48"
                                variant="light-success"
                              >
                                <feather-icon
                                  size="24"
                                  icon="DollarSignIcon"
                                />
                              </b-avatar>
                            </b-media-aside>
                            <b-media-body class="my-auto">
                              <h4 class="font-weight-bolder mb-0">
                                {{ item.livre }}
                              </h4>
                              <b-card-text class="font-small-3 mb-0">
                                {{ $t('spot.meuAgente.disponivelParaOperar') }}
                              </b-card-text>
                              <b-progress
                                :value="item.livrePerc"
                                max="100"
                                class="progress-bar-success"
                                variant="success"
                              />
                            </b-media-body>
                          </b-media>
                        </b-button>
                      </b-col>
                      <b-col
                        xl="3"
                        sm="6"
                        class="mb-xl-0"
                      >
                        <b-button
                          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                          v-b-tooltip.hover.top="$t('spot.meuAgente.saldoUtilizado')"
                          variant="flat-warning"
                          class="btn-icon"
                          block
                        >
                          <b-media no-body>
                            <b-media-aside

                              class="mr-2"
                            >
                              <b-avatar
                                size="48"
                                variant="light-warning"
                              >
                                <feather-icon
                                  size="24"
                                  icon="BarChart2Icon"
                                />
                              </b-avatar>
                            </b-media-aside>
                            <b-media-body class="my-auto">
                              <h4 class="font-weight-bolder mb-0">
                                {{ item.bloqueado }}
                              </h4>
                              <b-card-text class="font-small-3 mb-0">
                                {{ $t('spot.meuAgente.textSaldoUtilizado') }}
                              </b-card-text>
                              <b-progress
                                :value="item.bloqueadoPerc"
                                max="100"
                                class="progress-bar-warning"
                                variant="warning"
                              />
                            </b-media-body>
                          </b-media>
                        </b-button>
                      </b-col>
                      <b-col
                        xl="3"
                        sm="6"
                        class="mb-xl-0"
                      >
                        <b-button
                          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                          v-b-tooltip.hover.top="$t('spot.meuAgente.saldoTotalDisponibilizado')"
                          variant="flat-primary"
                          class="btn-icon"
                          block
                        >
                          <b-media no-body>
                            <b-media-aside

                              class="mr-2"
                            >
                              <b-avatar
                                size="48"
                                variant="light-primary"
                              >
                                <feather-icon
                                  size="24"
                                  icon="ActivityIcon"
                                />
                              </b-avatar>
                            </b-media-aside>
                            <b-media-body class="my-auto">
                              <h4 class="font-weight-bolder mb-0">
                                {{ item.total }}
                              </h4>
                              <b-card-text class="font-small-3 mb-0">
                                <template v-if="item.limitaSaldo">
                                  {{ $t('spot.meuAgente.saldoTotalLimitado') }}
                                </template>
                                <template v-else>
                                  {{ $t('spot.meuAgente.saldoTotal') }}
                                </template>
                              </b-card-text>
                              <b-progress
                                :value="item.totalPerc"
                                max="100"
                              />
                            </b-media-body>
                          </b-media>
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-col>
              </b-row>
            </b-tab>

          </b-tabs>
        </b-card>

        <b-card :title="$t('spot.meuAgente.titleConfiguracoes')">
          <validation-observer ref="simpleRules">
            <b-form>
              <b-tabs
                content-class="pt-1"
                justified
              >
                <b-tab
                  v-for="config in configs"
                  :key="config.idPerfil"
                  :title="configs.length > 1? config.perfil: ''"
                >
                  <h4 class="mb-2">
                    {{ $t('spot.meuAgente.primeiroPasso') }}
                  </h4>

                  <b-row class="mb-2">
                    <b-col cols="12">
                      <h5 class="font-medium">
                        {{ config.status=='Desligado' ? $t('spot.meuAgente.cliqueSimInvestir') : $t('spot.meuAgente.cliqueNaoPausar') }}
                      </h5>
                      <div class="demo-inline-spacing">
                        <b-form-radio-group v-model="config.status">
                          <b-form-radio
                            name="some-radios"
                            value="Ligado"
                            class="custom-control-success"
                          >
                            {{ $t('spot.meuAgente.sim') }}
                          </b-form-radio>
                          <b-form-radio
                            name="some-radios"
                            value="Desligado"
                            class="custom-control-danger"
                          >
                            {{ $t('spot.meuAgente.nao') }}
                          </b-form-radio>
                        </b-form-radio-group>
                      </div>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12">
                      <app-collapse
                        accordion
                        type="border"
                      >
                        <app-collapse-item :title="$t('spot.meuAgente.titleOpcoesAvancadas')">
                          <b-row>
                            <b-col lg="6">
                              <b-row>
                                <b-col lg="12">
                                  <div>{{ $t('spot.meuAgente.seguirRecomendacaoMoedasAgente') }}</div>
                                  <div class="demo-inline-spacing mb-2">
                                    <b-form-radio-group v-model="config.aceitaWhiteList">
                                      <b-form-radio
                                        name="aceitaWhiteList"
                                        value="true"
                                      >
                                        {{ $t('spot.meuAgente.sim') }}
                                      </b-form-radio>
                                      <b-form-radio
                                        name="aceitaWhiteList"
                                        value="false"
                                      >
                                        {{ $t('spot.meuAgente.nao') }}
                                      </b-form-radio>
                                    </b-form-radio-group>
                                  </div>

                                  <b-form-group
                                    :label="$t('spot.meuAgente.aceitarSomenteMoedas')"
                                    label-for="aceita-moeda"
                                    :description="$t('spot.meuAgente.deixeEmBrancoAceitarTodasMoedas')"
                                  >
                                    <b-form-textarea
                                      v-if="config.aceitaWhiteList=='false'"
                                      v-model="config.whiteList"
                                      @input="config.whiteList = config.whiteList.toUpperCase()"
                                    />
                                    <b-form-textarea
                                      v-else
                                      v-model="config.whiteListAgente"
                                      disabled
                                    />
                                  </b-form-group>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col lg="12">
                                  <b-form-group
                                    :label="$t('spot.meuAgente.ignorarAsMoedas')"
                                    label-for="ignora-moeda"
                                    :description="$t('spot.meuAgente.deixeEmBrancoNaoIgnorarMoeda')"
                                  >
                                    <b-form-textarea
                                      v-model="config.blackList"
                                      d:escription="$t('spot.meuAgente.useVirgulaParaSeparar')"
                                      @input="config.blackList = config.blackList.toUpperCase()"
                                    />
                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col lg="6">
                              <b-row>
                                <b-col lg="12">
                                  <b-form-group
                                    :label="$t('spot.meuAgente.cancelarCompraNaoExecutada')"
                                    label-for="cancel-compra"
                                  >
                                    <b-form-select
                                      v-model="config.stopCompraPorcentagem"
                                      :options="stopOptions(config)"
                                      input-id="cancel-compra"
                                    />
                                  </b-form-group>
                                </b-col>
                                <b-col lg="12">
                                  <b-form-group
                                    :label="$t('spot.meuAgente.tipoVenda')"
                                    label-for="tipo-venda"
                                  >
                                    <b-form-select
                                      v-model="config.tipoOrdemVenda"
                                      :options="tipoOrdemVendaOptions(config)"
                                      input-id="tipo-venda"
                                      @change="alteraTipoVenda(config)"
                                    />
                                  </b-form-group>
                                </b-col>
                                <b-col lg="12">
                                  <b-form-group
                                    :label="$t('spot.meuAgente.stoplossMax')"
                                    label-for="stoploss-max"
                                  >
                                    <b-form-select
                                      v-model="config.stoplossLimit"
                                      :disabled="isTipoVendaLimite(config)"
                                      :options="stopVendaLimit"
                                      input-id="stoploss-max"
                                    />
                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </app-collapse-item>
                      </app-collapse>
                    </b-col>
                  </b-row>

                  <hr>
                  <h4 class="mb-2">
                    {{ $t('spot.meuAgente.segundoPasso') }}
                  </h4>
                  <h5 class="font-medium">
                    {{ $t('spot.meuAgente.escolhaMoedasBases') }}
                  </h5>

                  <b-tabs
                    content-class="pt-1"
                    justified
                  >
                    <b-tab
                      v-if="isHabilitaParidade('USDT')"
                      title="USDT"
                    >
                      <b-row class="mb-2">
                        <b-col cols="12">
                          <h5 class="mb-2 text-muted">
                            {{ $t('spot.meuAgente.usdtDescricao') }}
                          </h5>
                          <h5 class="font-medium">
                            {{ $t('spot.meuAgente.ativarUSDTInvestimento') }}
                          </h5>
                          <div class="demo-inline-spacing">
                            <b-form-radio-group v-model="config.paridadeUSD">
                              <b-form-radio
                                name="ativar-usd"
                                value="true"
                                class="custom-control-success"
                              >
                                {{ $t('spot.meuAgente.sim') }}
                              </b-form-radio>
                              <b-form-radio
                                name="ativar-usd"
                                value="false"
                                class="custom-control-danger"
                              >
                                {{ $t('spot.meuAgente.nao') }}
                              </b-form-radio>
                            </b-form-radio-group>
                          </div>
                        </b-col>
                      </b-row>
                      <b-row class="mb-2">
                        <b-col cols="12">
                          <h5 class="font-medium">
                            {{ $t('spot.meuAgente.quantoQuerInvestir') }}
                          </h5>
                          <div class="demo-inline-spacing">
                            <b-form-radio-group v-model="config.tipoValorUSD">
                              <b-form-radio
                                name="tipo-valor-usd"
                                value="A"
                                @change="trocaTipoValorUSD(config)"
                              >
                                {{ $t('spot.meuAgente.emValor') }}
                              </b-form-radio>
                              <b-form-radio
                                name="tipo-valor-usd"
                                value="P"
                                @change="trocaTipoValorUSD(config)"
                              >
                                {{ $t('spot.meuAgente.emPorcentagem') }}
                              </b-form-radio>
                            </b-form-radio-group>
                          </div>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="4">
                          <b-form-group
                            label-for="precoMaxOrdemUSD"
                          >
                            <label v-if="config.tipoValorUSD=='A'">{{ $t('spot.meuAgente.valorPorOperacao') }}</label>
                            <label v-else>{{ $t('spot.meuAgente.percentualPorOperacao') }}</label>
                            <small
                              v-if="config.tipoValorUSD=='A'"
                              class="text-muted"
                            >
                              <i>  {{ $t('spot.meuAgente.exemploValor') }}</i>
                            </small>
                            <small
                              v-else
                              class="text-muted"
                            >
                              <i>  {{ $t('spot.meuAgente.exemploPercentual') }}</i>
                            </small>
                            <validation-provider
                              #default="{ errors }"
                              :rules="{ required: config.paridadeUSD === 'true', regex: /^[+]?\d+(\.\d+)?$/, min_value: config.tipoValorUSD==='A' ? 12:0.1, max_value:config.tipoValorUSD==='A'? 99999 : 99 }"
                              :name="config.tipoValorUSD === 'A'? $t('spot.meuAgente.valorPorOperacao'): $t('spot.meuAgente.percentualPorOperacao')"
                            >
                              <b-form-input
                                id="precoMaxOrdemUSD"
                                v-model="config.precoMaxOrdemUSD"
                                :state="errors.length > 0 ? false: null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <app-collapse
                        accordion
                        type="border"
                      >
                        <app-collapse-item :title="$t('spot.meuAgente.opcoesAvancadasUSDT')">
                          <b-row>
                            <b-col lg="4">
                              <b-form-group :description="$t('spot.meuAgente.deixeEmBrancoParaOperar')">
                                <label v-if="config.tipoValorUSD=='A'">{{ $t('spot.meuAgente.valorTotalDisponibilizar') }}</label>
                                <label v-else> {{ $t('spot.meuAgente.percentualTotalDisponibilizar') }}</label>
                                <small
                                  v-if="config.tipoValorUSD=='A'"
                                  class="text-muted"
                                >
                                  <i>  {{ $t('spot.meuAgente.exemploValorTotal') }}</i>
                                </small>
                                <small
                                  v-else
                                  class="text-muted"
                                >
                                  <i>  {{ $t('spot.meuAgente.exemploPercentualTotal') }}</i>
                                </small>
                                <validation-provider
                                  #default="{ errors }"
                                  :rules="{ regex: /^[+]?\d+(\.\d+)?$/, min_value: config.tipoValorUSD==='A' ? 12:0.1, max_value:config.tipoValorUSD==='A'? 99999 : 99 }"
                                  :name="config.tipoValorUSD==='A'? $t('spot.meuAgente.valorTotalDisponibilizar'): $t('spot.meuAgente.percentualTotalDisponibilizar')"
                                >
                                  <b-form-input
                                    id="precoMaxTotalUSD"
                                    v-model="config.precoMaxTotalUSD"
                                    :state="errors.length > 0 ? false: null"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col lg="12">
                              <b-row>
                                <b-col lg="4">
                                  <b-form-group
                                    :label="$t('spot.meuAgente.saida')"
                                    label-for="alvo-usd"
                                  >
                                    <b-form-select
                                      v-model="config.alvoVendaUSD"
                                      :options="alvosOptionsUSD(config)"
                                      input-id="alvo-usd"
                                      @change="alteraAlvo('USD',config)"
                                    />
                                  </b-form-group>
                                </b-col>
                              </b-row>
                              <b-row v-if="config.alvoVendaUSD=='0'">
                                <b-col lg="2">
                                  <b-form-group
                                    :label="$t('spot.meuAgente.primeiraSaida')"
                                    label-for="alvo1-usd"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      :rules="{ required: config.paridadeUSD == 'true' && config.alvoVendaUSD == '0' }"
                                      name="Primeira saída (%)"
                                    >
                                      <b-form-select
                                        v-model="config.alvoVendaParcial1USD"
                                        :options="alvosPorcentagemOptions"
                                        input-id="alvo1-usd"
                                        :state="errors.length > 0 ? false: null"
                                      />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <b-col lg="2">
                                  <b-form-group
                                    :label="$t('spot.meuAgente.SegundaSaida')"
                                    label-for="alvo2-usd"
                                  >
                                    <b-form-input
                                      id="alvo2-usfd"
                                      disabled
                                      :value="100-config.alvoVendaParcial1USD"
                                    />
                                  </b-form-group>
                                </b-col>
                              </b-row>
                              <b-row v-if="config.alvoVendaUSD=='0'">
                                <b-col lg="6">
                                  <b-alert
                                    show
                                    variant="warning"
                                  >
                                    <div class="alert-body">
                                      <span class="ml-25">{{ $t('spot.meuAgente.avisoAlvoInferior') }}</span>
                                    </div>
                                  </b-alert>
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </app-collapse-item>
                      </app-collapse>
                    </b-tab>

                    <b-tab
                      v-if="isHabilitaParidade('BTC')"
                      title="Bitcoin"
                    >
                      <b-row class="mb-2">
                        <b-col cols="12">
                          <h5 class="mb-2 text-muted">
                            {{ $t('spot.meuAgente.descricaoBitcoin') }}
                          </h5>
                          <h5 class="font-medium">
                            {{ $t('spot.meuAgente.ativarInvestimentosBitcoin') }}
                          </h5>
                          <div class="demo-inline-spacing">
                            <b-form-radio-group v-model="config.paridadeBTC">
                              <b-form-radio
                                name="ativar-btc"
                                value="true"
                                class="custom-control-success"
                              >
                                {{ $t('spot.meuAgente.sim') }}
                              </b-form-radio>
                              <b-form-radio
                                name="ativar-btc"
                                value="false"
                                class="custom-control-danger"
                              >
                                {{ $t('spot.meuAgente.nao') }}
                              </b-form-radio>
                            </b-form-radio-group>
                          </div>
                        </b-col>
                      </b-row>
                      <b-row class="mb-2">
                        <b-col cols="12">
                          <h5 class="font-medium">
                            {{ $t('spot.meuAgente.quantoInvestir') }}
                          </h5>
                          <div class="demo-inline-spacing">
                            <b-form-radio-group v-model="config.tipoValorBTC">
                              <b-form-radio
                                name="tipo-valor-btc"
                                value="A"
                                @change="trocaTipoValorBTC(config)"
                              >
                                {{ $t('spot.meuAgente.emValor') }}
                              </b-form-radio>
                              <b-form-radio
                                name="tipo-valor-btc"
                                value="P"
                                @change="trocaTipoValorBTC(config)"
                              >
                                {{ $t('spot.meuAgente.emPorcentagem') }}
                              </b-form-radio>
                            </b-form-radio-group>
                          </div>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="4">
                          <b-form-group
                            label-for="precoMaxOrdemBTC"
                          >
                            <label v-if="config.tipoValorBTC=='A'">{{ $t('spot.meuAgente.valorPorOperacao') }}</label>
                            <label v-else>{{ $t('spot.meuAgente.percentualPorOperacao') }}</label>
                            <small
                              v-if="config.tipoValorBTC=='A'"
                              class="text-muted"
                            >
                              <i>  {{ $t('spot.meuAgente.exemploValorPorOperacao') }}</i>
                            </small>
                            <small
                              v-else
                              class="text-muted"
                            >
                              <i>  {{ $t('spot.meuAgente.exemploPercentualPorOperacao') }}</i>
                            </small>
                            <validation-provider
                              #default="{ errors }"
                              :rules="{ required: config.paridadeBTC === 'true', regex: /^[+]?\d+(\.\d+)?$/, min_value: config.tipoValorBTC==='A' ? 0.0004:0.1, max_value:config.tipoValorBTC==='A'? 1 : 99 }"
                              :name="config.tipoValorBTC === 'A'? $t('spot.meuAgente.valorPorOperacao'): $t('spot.meuAgente.percentualPorOperacao')"
                            >
                              <b-form-input
                                id="precoMaxOrdemBTC"
                                v-model="config.precoMaxOrdemBTC"
                                :state="errors.length > 0 ? false: null"
                              />
                              <small class="form-text text-muted">{{ config.tipoValorBTC=='A'?$t('spot.meuAgente.aproximadamente') + (config.precoMaxOrdemBTC * cotacaoBTCUSDT).toFixed(2)+' USD':'' }}</small>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <app-collapse
                        accordion
                        type="border"
                      >
                        <app-collapse-item :title="$t('spot.meuAgente.opcoesAvancadasBTC')">
                          <b-row>
                            <b-col lg="4">
                              <b-form-group :description="$t('spot.meuAgente.deixeEmBrancoParaOperar')">
                                <label v-if="config.tipoValorBTC=='A'">{{ $t('spot.meuAgente.valorTotalDisponibilizar') }}</label>
                                <label v-else> {{ $t('spot.meuAgente.percentualTotalDisponibilizar') }}</label>
                                <small
                                  v-if="config.tipoValorBTC=='A'"
                                  class="text-muted"
                                >
                                  <i>  {{ $t('spot.meuAgente.exemploValorTotalDisponibilizar') }}</i>
                                </small>
                                <small
                                  v-else
                                  class="text-muted"
                                >
                                  <i>  {{ $t('spot.meuAgente.exemploPercentualTotal') }}</i>
                                </small>
                                <validation-provider
                                  #default="{ errors }"
                                  :rules="{ regex: /^[+]?\d+(\.\d+)?$/, min_value: config.tipoValorBTC==='A' ? 0.0004:0.1, max_value:config.tipoValorBTC==='A'? 2 : 99 }"
                                  :name="config.tipoValorBTC==='A'? $t('spot.meuAgente.valorTotalDisponibilizar'): $t('spot.meuAgente.percentualTotalDisponibilizar')"
                                >
                                  <b-form-input
                                    id="precoMaxTotalBTC"
                                    v-model="config.precoMaxTotalBTC"
                                    :state="errors.length > 0 ? false: null"
                                  />
                                  <small class="form-text text-muted">{{ config.tipoValorBTC=='A'?$t('spot.meuAgente.aproximadamente')+ (config.precoMaxTotalBTC * cotacaoBTCUSDT).toFixed(2)+' USD':'' }}</small>
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col lg="12">
                              <b-row>
                                <b-col lg="4">
                                  <b-form-group
                                    :label="$t('spot.meuAgente.saida')"
                                    label-for="alvo-btc"
                                  >
                                    <b-form-select
                                      v-model="config.alvoVendaBTC"
                                      :options="alvosOptionsBTC(config)"
                                      input-id="alvo-btc"
                                      @change="alteraAlvo('BTC',config)"
                                    />
                                  </b-form-group>
                                </b-col>
                              </b-row>
                              <b-row v-if="config.alvoVendaBTC=='0'">
                                <b-col lg="2">
                                  <b-form-group
                                    :label="$t('spot.meuAgente.primeiraSaida')"
                                    label-for="alvo1-btc"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      :rules="{ required: config.paridadeBTC=='true' && config.alvoVendaBTC=='0' }"
                                      name="Primeira saída (%)"
                                    >
                                      <b-form-select
                                        v-model="config.alvoVendaParcial1BTC"
                                        :options="alvosPorcentagemOptions"
                                        input-id="alvo1-btc"
                                        :state="errors.length > 0 ? false: null"
                                      />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <b-col lg="2">
                                  <b-form-group
                                    :label="$t('spot.meuAgente.segundaSaida')"
                                    label-for="alvo2-btc"
                                  >
                                    <b-form-input
                                      id="alvo2-btc"
                                      disabled
                                      :value="100-config.alvoVendaParcial1BTC"
                                    />
                                  </b-form-group>
                                </b-col>
                              </b-row>
                              <b-row v-if="config.alvoVendaBTC=='0'">
                                <b-col lg="6">
                                  <b-alert
                                    show
                                    variant="warning"
                                  >
                                    <div class="alert-body">
                                      <span class="ml-25">{{ $t('spot.meuAgente.alertaBTC') }}</span>
                                    </div>
                                  </b-alert>
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </app-collapse-item>
                      </app-collapse>
                    </b-tab>

                    <b-tab
                      v-if="isHabilitaParidade('BRL')"
                      title="BRL"
                    >
                      <b-row class="mb-2">
                        <b-col cols="12">
                          <h5 class="mb-2 text-muted">
                            {{ $t('spot.meuAgente.descricaoBRL') }}
                          </h5>
                          <h5 class="font-medium">
                            {{ $t('spot.meuAgente.ativarInvestimentosBRL') }}
                          </h5>
                          <div class="demo-inline-spacing">
                            <b-form-radio-group v-model="config.paridadeBRL">
                              <b-form-radio
                                name="ativar-brl"
                                value="true"
                                class="custom-control-success"
                              >
                                {{ $t('spot.meuAgente.sim') }}
                              </b-form-radio>
                              <b-form-radio
                                name="ativar-brl"
                                value="false"
                                class="custom-control-danger"
                              >
                                {{ $t('spot.meuAgente.nao') }}
                              </b-form-radio>
                            </b-form-radio-group>
                          </div>
                        </b-col>
                      </b-row>
                      <b-row class="mb-2">
                        <b-col cols="12">
                          <h5 class="font-medium">
                            {{ $t('spot.meuAgente.quantoInvestir') }}

                          </h5>
                          <div class="demo-inline-spacing">
                            <b-form-radio-group v-model="config.tipoValorBRL">
                              <b-form-radio
                                name="tipo-valor-brl"
                                value="A"
                                @change="trocaTipoValorBRL(config)"
                              >
                                {{ $t('spot.meuAgente.emValor') }}
                              </b-form-radio>
                              <b-form-radio
                                name="tipo-valor-brl"
                                value="P"
                                @change="trocaTipoValorBRL(config)"
                              >
                                {{ $t('spot.meuAgente.emPorcentagem') }}
                              </b-form-radio>
                            </b-form-radio-group>
                          </div>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="4">
                          <b-form-group
                            label-for="precoMaxOrdemBRL"
                          >
                            <label v-if="config.tipoValorBRL=='A'">{{ $t('spot.meuAgente.valorPorOperacao') }}</label>
                            <label v-else>{{ $t('spot.meuAgente.percentualPorOperacao') }}</label>
                            <small
                              v-if="config.tipoValorBRL=='A'"
                              class="text-muted"
                            >
                              <i>  {{ $t('spot.meuAgente.exemploValor') }}</i>
                            </small>
                            <small
                              v-else
                              class="text-muted"
                            >
                              <i>  {{ $t('spot.meuAgente.exemploPercentual') }}</i>
                            </small>
                            <validation-provider
                              #default="{ errors }"
                              :rules="{ required: config.paridadeBRL === 'true', regex: /^[+]?\d+(\.\d+)?$/, min_value: config.tipoValorBRL==='A' ? 12:0.1, max_value:config.tipoValorBRL==='A'? 99999 : 99 }"
                              :name="config.tipoValorBRL === 'A'? $t('spot.meuAgente.valorPorOperacao'): $t('spot.meuAgente.percentualPorOperacao')"
                            >
                              <b-form-input
                                id="precoMaxOrdemBRL"
                                v-model="config.precoMaxOrdemBRL"
                                :state="errors.length > 0 ? false: null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <app-collapse
                        accordion
                        type="border"
                      >
                        <app-collapse-item :title="$t('spot.meuAgente.opcoesAvancadasBRL')">
                          <b-row>
                            <b-col lg="4">
                              <b-form-group :description="$t('spot.meuAgente.deixeEmBrancoParaOperar')">
                                <label v-if="config.tipoValorBRL=='A'">{{ $t('spot.meuAgente.valorTotalDisponibilizar') }}</label>
                                <label v-else> {{ $t('spot.meuAgente.percentualTotalDisponibilizar') }}</label>
                                <small
                                  v-if="config.tipoValorBRL=='A'"
                                  class="text-muted"
                                >
                                  <i> {{ $t('spot.meuAgente.exemploValorTotal') }}</i>
                                </small>
                                <small
                                  v-else
                                  class="text-muted"
                                >xe
                                  <i>  {{ $t('spot.meuAgente.exemploPercentualTotal') }}</i>
                                </small>
                                <validation-provider
                                  #default="{ errors }"
                                  :rules="{ regex: /^[+]?\d+(\.\d+)?$/, min_value: config.tipoValorBRL==='A' ? 12:0.1, max_value:config.tipoValorBRL==='A'? 99999 : 99 }"
                                  :name="config.tipoValorBRL==='A'? $t('spot.meuAgente.valorTotalDisponibilizar'): $t('spot.meuAgente.percentualTotalDisponibilizar')"
                                >
                                  <b-form-input
                                    id="precoMaxTotalBRL"
                                    v-model="config.precoMaxTotalBRL"
                                    :state="errors.length > 0 ? false: null"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col lg="12">
                              <b-row>
                                <b-col lg="4">
                                  <b-form-group
                                    :label="$t('spot.meuAgente.saida')"
                                    label-for="alvo-brl"
                                  >
                                    <b-form-select
                                      v-model="config.alvoVendaBRL"
                                      :options="alvosOptionsBRL(config)"
                                      input-id="alvo-brl"
                                      @change="alteraAlvo('BRL',config)"
                                    />
                                  </b-form-group>
                                </b-col>
                              </b-row>
                              <b-row v-if="config.alvoVendaBRL=='0'">
                                <b-col lg="2">
                                  <b-form-group
                                    :label="$t('spot.meuAgente.primeiraSaida')"
                                    label-for="alvo1-brl"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      :rules="{ required: config.paridadeBRL == 'true' && config.alvoVendaBRL == '0' }"
                                      name="Primeira saída (%)"
                                    >
                                      <b-form-select
                                        v-model="config.alvoVendaParcial1BRL"
                                        :options="alvosPorcentagemOptions"
                                        input-id="alvo1-brl"
                                        :state="errors.length > 0 ? false: null"
                                      />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <b-col lg="2">
                                  <b-form-group
                                    :label="$t('spot.meuAgente.segundaSaida')"
                                    label-for="alvo2-brl"
                                  >
                                    <b-form-input
                                      id="alvo2-brl"
                                      disabled
                                      :value="100-config.alvoVendaParcial1BRL"
                                    />
                                  </b-form-group>
                                </b-col>
                              </b-row>
                              <b-row v-if="config.alvoVendaBRL=='0'">
                                <b-col lg="6">
                                  <b-alert
                                    show
                                    variant="warning"
                                  >
                                    <div class="alert-body">
                                      <span class="ml-25">{{ $t('spot.meuAgente.avisoAlvoInferior') }}</span>
                                    </div>
                                  </b-alert>
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </app-collapse-item>
                      </app-collapse>
                    </b-tab>

                  </b-tabs>
                </b-tab>
              </b-tabs>
              <b-col
                v-show="configs"
                cols="12"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-1 mr-1"
                  @click.prevent="confirmarSalvar"
                >
                  {{ $t('spot.meuAgente.salvarBtn') }}
                </b-button>
              </b-col>
            </b-form>
          </validation-observer>
        </b-card>

      </template>

    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, minValue, maxValue } from '@validations'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/@core/components/app-collapse/AppCollapseItem.vue'
import {
 BRow, BCard, BCol, BAvatar, BButton, BCardText, VBTooltip, BProgress, BOverlay, BTab, BTabs, BFormRadioGroup, BFormRadio, BFormInput, BFormTextarea, BFormGroup, BFormSelect, BForm, BAlert, BCardBody, BMedia, BMediaAside, BMediaBody,
} from 'bootstrap-vue'
import { subscribeSymbol } from '@/services/binance/spot/binance'
import Ripple from 'vue-ripple-directive'
import utilsMixin from '@/mixins/utilsMixin'

export default {

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  mixins: [utilsMixin],

  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCard,
    BCol,
    BAvatar,
    BButton,
    BCardText,
    VBTooltip,
    BProgress,
    BOverlay,
    BTab,
    BTabs,
    BFormRadio,
    BFormRadioGroup,
    AppCollapse,
    AppCollapseItem,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BFormSelect,
    BForm,
    BAlert,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
  },

  data() {
    return {
      loadingOverlay: false,
      user_data: {
        resumoSaldos: []
      },
      required,
      minValue,
      maxValue,
      errorMessage: null,

      alvosPorcentagemOptions: [
        {text: "30", value: 30},
        {text: "40", value: 40},
        {text: "50", value: 50},
        {text: "60", value: 60},
        {text: "70", value: 70},
      ],

    }
  },

  computed: {
    stopVendaLimit(){
      return [
        {value: null, text: this.$i18n.t('spot.meuAgente.seguirAgente')},
        {value: "2", text: '2%'},
        {value: "3", text: '3%'},
        {value: "4", text: '4%'},
        {value: "5", text: '5%'},
        {value: "6", text: '6%'},
        {value: "7", text: '7%'},
        {value: "8", text: '8%'},
        {value: "9", text: '9%'},
        {value: "10", text: '10%'},
      ]
    },
    alvosOptions() {
      return [
        {value: "1", text: this.$i18n.t('spot.meuAgente.primeiroAlvo')},
        {value: "2", text: this.$i18n.t('spot.meuAgente.segundoAlvo')}
      ]
    },
    alvosOptionsPersonalizado() {
      return [
        {value: "1", text: this.$i18n.t('spot.meuAgente.primeiroAlvo')},
        {value: "2", text: this.$i18n.t('spot.meuAgente.segundoAlvo')},
        {value: "0", text: this.$i18n.t('spot.meuAgente.personalizado')}
      ]
    }, 
    configs(){
      return this.user_data.configs
    },

    cotacaoBTCUSDT() {
      if(this.$store.state.binanceSpot.tickers && this.$store.state.binanceSpot.tickers['BTCUSDT']){
        return this.$store.state.binanceSpot.tickers['BTCUSDT'].price
      }
    },
  },

  methods: {

    isHabilitaParidade(paridade){
      if(this.user_data.resumoSaldos){
        return this.user_data.resumoSaldos.some(saldo => saldo.paridade === paridade)
      }
    },

    tipoOrdemVendaOptions(config){

      const tipoOrdemVendaOptionsInt = [
        {value: "OCO", text: 'OCO (Com stop)'},
        {value: "Limite", text: 'Limite (Sem stop)'}
      ]

      const textAgente = tipoOrdemVendaOptionsInt.find(v=> v.value==config.tipoOrdemVendaAgente).text
      tipoOrdemVendaOptionsInt.unshift({value: null, text: `Seguir agente (${textAgente})`})

      return tipoOrdemVendaOptionsInt;
    },

    stopOptions(config){

      const stopOptionsInt = [
        {value: "0.5", text: 'Se subir 0.50%'},
        {value: "0.65", text: 'Se subir 0.65%'},
        {value: "0.80", text: 'Se subir 0.80%'},
        {value: "1", text: 'Se subir 1%'},
        {value: "0", text: 'Não cancelar'}
      ]

      const textAgente = stopOptionsInt.find(v=> v.value==config.stopCompraPorcentagemAgente).text
      stopOptionsInt.unshift({value: null, text: `Seguir agente (${textAgente})`})

      return stopOptionsInt;
    },

    isTipoVendaLimite(config){

      if(config.tipoOrdemVenda==null){
        if(config.tipoOrdemVendaAgente=='Limite'){
          return true;
        }
      }else if(config.tipoOrdemVenda=='Limite'){
          return true;
      }
        return false;
    },

    alteraTipoVenda(config){
      if(config.tipoOrdemVenda=="OCO"){
        if(config.alvoVendaBTC=="0"){
          config.alvoVendaBTC =1
        }
        if(config.alvoVendaUSDT=="0"){
          config.alvoVendaUSDT =1
        }
        if(config.alvoVendaBRL=="0"){
          config.alvoVendaBRL =1
        }
      }
    },

    alvosOptionsBTC: function(config){
      var options = [];
        options.push({text: "Alvo 1 (Recomendado)", value:"1"})
        options.push({text: "Alvo 2", value:"2"})
        // if(this.isTipoVendaLimite(config)){
         //  options.push({text: "Personalizado", value:"0"})
        // }
      return options;
    },

    alvosOptionsUSD: function(config){
      var options = [];
        options.push({text: "Alvo 1 (Recomendado)", value:"1"})
        options.push({text: "Alvo 2", value:"2"})
        // if(this.isTipoVendaLimite(config)){
          // options.push({text: "Personalizado", value:"0"})
        // }
      return options;
    },

    alvosOptionsBRL: function(config){
      var options = [];
        options.push({text: "Alvo 1 (Recomendado)", value:"1"})
        options.push({text: "Alvo 2", value:"2"})
        // if(this.isTipoVendaLimite(config)){
          // options.push({text: "Personalizado", value:"0"})
        // }
      return options;
    },

    alteraAlvo(paridade, config){
      if(paridade=='USDT'){
        if(config.alvoVendaUSD=='0'){
          config.alvoVendaParcial1USD = 60
        }
      }else if(paridade=='BTC'){
        if(config.alvoVendaBTC=='0'){
          config.alvoVendaParcial1BTC = 60
        }
      }else if(paridade=='BRL'){
        if(config.alvoVendaBRL=='0'){
          config.alvoVendaParcial1BRL = 60
        }
      }
    },

    confirmarSalvar(id) {
      // validações que não estao sendo pegas pelo validator quando nao esta na aba ativa
      let mensagens = this.validacaoExtra(this.user_data)
      if (mensagens) {
          this.$swal({
            title: 'Error!',
            html: mensagens,
            icon: 'error',
            confirmButtonText: 'Ok, vou corrigir!',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
      } else {
        this.$swal({
          title: 'Confirmar as alterações',
          text: 'Revise os valores. Ao clicar no botão verde, você vai começar a investir os valores definidos. Também poderá pausar ou alterar a qualquer momento.',
          icon: 'warning',
          confirmButtonText: 'Ok, está tudo certo!',
          showCancelButton: true,
          cancelButtonText: 'Abortar',
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.submitForm()
          }
        })
      }
    },

    trocaTipoValorBTC(config){
      config.precoMaxTotalBTC = null;
      config.precoMaxOrdemBTC = null;
    },

    trocaTipoValorUSD(config){
      config.precoMaxTotalUSD = null;
      config.precoMaxOrdemUSD = null;
    },

    trocaTipoValorBRL(config){
      config.precoMaxTotalBRL = null;
      config.precoMaxOrdemBRL = null;
    },

    validacaoExtra(data){
      let mensagemValidacao = null
      data.configs.map(function(value, key) {
        if (!!value.precoMaxTotalBTC  && !/^[+]?\d+(\.\d+)?$/.test(value.precoMaxTotalBTC)) {
          mensagemValidacao = "O campo 'Valor total a disponibilizar (BTC)' possui um formato inválido. <br>"
        }
        if (!/^[+]?\d+(\.\d+)?$/.test(value.precoMaxOrdemBTC)) {
          mensagemValidacao += "O campo 'Valor por operação (BTC)' possui um formato inválido. <br>"
        }

        if (!!value.precoMaxTotalUSD && !/^[+]?\d+(\.\d+)?$/.test(value.precoMaxTotalUSD)) {
          mensagemValidacao += "O campo 'Valor total a disponibilizar (USD)' possui um formato inválido. <br>"
        }

        if (!/^[+]?\d+(\.\d+)?$/.test(value.precoMaxOrdemUSD)) {
          mensagemValidacao += "O campo 'Valor por operação (USD)' possui um formato inválido. <br>"
        }

        if (!!value.precoMaxTotalBRL && !/^[+]?\d+(\.\d+)?$/.test(value.precoMaxTotalBRL)) {
          mensagemValidacao += "O campo 'Valor total a disponibilizar (BRL)' possui um formato inválido. <br>"
        }

        if (!/^[+]?\d+(\.\d+)?$/.test(value.precoMaxOrdemBRL)) {
          mensagemValidacao += "O campo 'Valor por operação (BRL)' possui um formato inválido. <br>"
        }
      })
      return mensagemValidacao
    },

    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loadingOverlay = true
          this.$store.dispatch('meu-agente-spot/setMeuAgentePerfilConfig',  this.user_data.configs)
            .then(() => {
              this.getMeuAgente(this.$route.params.id)
              this.$swal({
                title: 'Sucesso!',
                text: 'Agente configurado com sucesso',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              this.$swal({
                title: 'Error!',
                text: error.response.data[0].erro,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
          }).finally(() => {
            this.loadingOverlay = false
          });
        }
      })
    },

    getMeuAgente(id) {
      this.loadingOverlay = true
      this.$store.dispatch('meu-agente-spot/getMeuAgenteOperacao', id)
        .then(res => {
          this.user_data = res.data
          this.errorMessage = null
        }).catch(error => {
          this.errorMessage = "API inválida. <a href='/tutorial/primeiros-passos/como-criar-api-binancei'>Clique aqui</a> para obter mais informações"
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          this.loadingOverlay = false
        });
    },

  },

  created() {
    this.getMeuAgente(this.$route.params.id)
      subscribeSymbol("BTCUSDT")
  }

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep {
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>